<template>
  <div>
    <div class="text-h5">Баннер</div>
    <div v-for="value, locale in block.content" :key="locale">
      {{ locale }}: {{ value }} <br>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  }
}
</script>
