<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    item-value="value"
    item-text="text"
    :items="items"
  />
</template>

<script>
import { TYPE_PHOTO, TYPE_EMBEDDED_VIDEO } from '@/models/GalleryItem'

export default {
  data () {
    return {
      items: [
        {
          value: TYPE_PHOTO,
          text: 'Photo'
        },
        {
          value: TYPE_EMBEDDED_VIDEO,
          text: 'Youtube video'
        }
      ]
    }
  }
}
</script>
