<template>
  <div>
    <v-select
      autofocus
      :items="options"
      item-text="label"
      item-value="name"
      label="Тип блока"
      v-model="form.name"
      outlined
      dense
      hide-details
    />


    <locale-field
      class="mt-4"
      component="v-text-field"
      rules="required"
      name="title"
      label="Заголовок"
      v-model="form.title"
      outlined
      dense
      hide-details
    />

    <block-editor-content
      name="content"
      class="mt-4"
      v-model="form.content"
      :mode="form.name"
      label="Текст"
    />

    <div class="mt-4">
      Отсутпы
    </div>

    <v-row>
      <validation-provider
        rules="required"
        v-slot="{ errors }"
        v-for="dir in ['top', 'bottom']"
        :key="dir"
        tag="div"
        slim
      >
        <v-col>
          <ClinicBlockMarginSelect
            :label="dir"
            v-model="form[dir]"
            :error-messages="errors[0]"
            outlined
            dense
            hide-details
          />
        </v-col>
      </validation-provider>
    </v-row>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import LocaleField from '../Locale/LocaleField.vue'
import ClinicBlockMarginSelect from './BlockMarginSelect.vue'
import BlockEditorContent from './BlockEditorContent.vue'
import { BLOCK_NAMES, BLOCK_MARGIN_MD, BLOCK_TYPE_CONTENT } from '../../services/clinics'

class Block {
  constructor (options) {
    const {
      name = null,
      title = {},
      content = {},
      order = 0,
      top = BLOCK_MARGIN_MD,
      bottom = BLOCK_MARGIN_MD
    } = options || {}
    Object.assign(this, { title, name, content, order, top, bottom })
  }
}

export default {
  components: {
    BlockEditorContent,
    LocaleField,
    ClinicBlockMarginSelect,
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      BLOCK_TYPE_CONTENT,
      names: Object.values(BLOCK_NAMES),
      form: new Block(this.value)
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.form)) return
        this.form = new Block(value)
      }
    },
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    }
  }
}
</script>
