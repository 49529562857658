import { v4 as uuid } from 'uuid'
export const TYPE_PHOTO = 'photo'
export const TYPE_EMBEDDED_VIDEO = 'embedded_video'

export default class GalleryItem {
  constructor (options) {
    const {
      id = uuid(),
      type = null,
      photo = null,
      embeddedVideo = null,
      title = {}
    } = options || {}
    this.id = id
    this.type = type
    this.photo = photo
    this.embeddedVideo = embeddedVideo
    this.title = title
  }
}
