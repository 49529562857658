<template>
  <AutocompleteAsync
    v-bind="attrs"
    v-on="$listeners"
  />
</template>

<script>
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'
import doctors from '../../services/doctors'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        name: "doctor",
        placeholder: this.$t('form.labels.doctor'),
        itemText: 'fullName',
        itemValue: 'id',
        load: (...args) => this.load(...args),
        ...this.$attrs,
      }
    }
  },
  methods: {
    async load (params) {
      const { items } = await doctors.getAll(params)
      return items
    },
  }
}
</script>
