<template>
  <div>
    <v-btn
      v-for="item in locales"
      :key="item"
      x-small
      @click="locale = item"
      :class="locale === item ? 'primary' : ''"
    >
      {{item }}
    </v-btn>

    <wysiwyg-content :content="content" v-if="content" class="mt-8" />
  </div>
</template>

<script>
import WysiwygContent from '@/components/WysiwygContent/WysiwygContent.vue'
import { locales } from '@/store/modules/app'

export default {
  components: {
    WysiwygContent
  },
  props: {
    block: {
      type: Object
    }
  },
  data () {
    return {
      locales,
      locale: 'ru'
    }
  },
  computed: {
    content () {
      return this.block.content[this.locale]
    }
  }
}
</script>
