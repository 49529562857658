<template>
  <div class="cgi">
    <validation-provider
      slim
      name="type"
      rules="required"
      v-slot="{ errors }"
    >
      <gallery-item-type-select
        v-model="form.type"
        label="Type"
        name="type"
        dense
        outlined
        :error-messages="errors"
      />
    </validation-provider>

    <gallery-item-content
      v-if="form.type"
      :type="form.type"
      v-model="form"
    />

    <locale-field
      component="v-textarea"
      auto-grow
      :rows="1"
      v-model="form.title"
      name="title"
      label="Подпись"
      outlined
      dense
      hide-details
    />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import ImageSelect from '@/components/elements/image-select.vue'
import LocaleField from '@/components/Locale/LocaleField.vue'
import GalleryItemTypeSelect from '@/components/Gallery/GalleryItemTypeSelect.vue'
import GalleryItemContent from '@/components/Gallery/GalleryItemContent.vue'
import GalleryItem from '@/models/GalleryItem'

export default {
  components: {
    GalleryItemContent,
    GalleryItemTypeSelect,
    LocaleField,
    ImageSelect
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      form: new GalleryItem(this.value)
    }
  },
  watch: {
    value (value) {
      const newValue = new GalleryItem(value)
      if (isEqual(value, newValue)) return
      this.form = newValue
    },
    form: {
      deep: true,
      handler () {
        this.$emit('input', this.form)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cgi__image {
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-bottom: 55%;
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
