<template>
  <v-alert type="error" dark color="red lighten-2">
    {{ error.message }}
  </v-alert>
</template>

<script>
export default {
  props: {
    error: {
      type: [Object, Error],
      required: true
    }
  }
}
</script>
