<template>
  <component
    v-if="component"
    :is="component.view"
    v-bind="component.bind"
    v-on="component.on"
  />
</template>

<script>
import GalleryItemPhoto from './GalleryItemPhoto.vue'
import GalleryItemEmbeddedVideo from './GalleryItemEmbeddedVideo.vue'
import { TYPE_PHOTO, TYPE_EMBEDDED_VIDEO } from '@/models/GalleryItem'
const types = { TYPE_PHOTO, TYPE_EMBEDDED_VIDEO }
const RATIO = 0.5

export default {
  props: {
    value: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
      validator: (prop) => Object.values(types).includes(prop),
    }
  },
  watch: {
    type (type) {
      const action = {
        [TYPE_PHOTO]: () => this.input({ embeddedVideo: null }),
        [TYPE_EMBEDDED_VIDEO]: () => this.input({ photo: null })
      }[type]
      if (action) {
        action()
      }
    }
  },
  computed: {
    component () {
      return {
        [TYPE_PHOTO]: {
          view: GalleryItemPhoto,
          bind: {
            ratio: RATIO,
            rules: 'required',
            value: this.value.photo
          },
          on: {
            input: (photo) => this.input({ photo })
          }
        },
        [TYPE_EMBEDDED_VIDEO]: {
          view: GalleryItemEmbeddedVideo,
          bind: {
            rules: 'required',
            ratio: RATIO,
            value: this.value.embeddedVideo
          },
          on: {
            input: (embeddedVideo) => this.input({ embeddedVideo })
          }
        }
      }[this.type]
    }
  },
  methods: {
    input (payload) {
      this.$emit('input', {
        ...this.value,
        ...payload,
      })
    }
  }
}
</script>
