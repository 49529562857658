<template>
  <component
    :is="view"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import BlockEditorContentSimple from './BlockEditorContentSimple.vue'
import BlockEditorContentExtended from './BlockEditorContentExtended.vue'
import { BLOCK_TYPE_CONTENT } from '../../services/clinics'

export default {
  props: {
    mode: {
      type: String,
    }
  },
  computed: {
    view () {
      return this.mode === BLOCK_TYPE_CONTENT
        ? BlockEditorContentExtended
        : BlockEditorContentSimple
    }
  }
}
</script>
