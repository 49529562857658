<template>
  <AutocompleteAsync
    v-bind="attrs"
    v-on="$listeners"
  />
</template>

<script>
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'
import services from '../../services/service'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        placeholder: 'Выберите услугу',
        ...this.$attrs,
        name: "service",
        itemText: 'name',
        itemValue: 'id',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    async load (params) {
      const { items } = await services.getAll(params)
      return items
    },
  }
}
</script>
