<template>
  <div>
    {{ value ? value.ru : null }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    }
  }
}
</script>
