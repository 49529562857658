<template>
  <div class="video-embed">
    <validation-provider slim name="embed" :rules="rules" v-slot="{ errors }">
      <v-text-field
        name="embed"
        dense
        outlined
        :value="value"
        @input="(e) => $emit('input', e)"
        label="YouTube video id"
        :error-messages="errors"
      />

      <gallery-item-you-tube-video
        v-if="value"
        :ratio="ratio"
        :video-id="value"
      />

    </validation-provider>
  </div>
</template>

<script>
import GalleryItemYouTubeVideo from './GalleryItemYouTubeVideo.vue'

export default {
  components: {
    GalleryItemYouTubeVideo,
  },
  props: {
    rules: {
      type: [String, Object]
    },
    ratio: {
      type: [Number, Function],
      required: true
    },
    value: {
      type: String
    }
  },
}
</script>
