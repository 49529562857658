<template>
  <list-editor-light
    :dialog-options="{ maxWidth: 800 }"
    v-model="source.blocks"
    @input="onInput"
  >
    <template v-slot="{ list, create, remove, prev, edit, next }">
      <v-btn block x-large @click="create" color="primary">
        <v-icon x-small left>fa-plus</v-icon>
        Добавить
      </v-btn>

      <v-row>
        <v-col
          :cols="12"
          :class="[
            item.top === 'sm' ? 'mt-0' : item.top === 'md' ? 'mt-8' : '',
            item.bottom === 'sm' ? 'mb-0' : item.bottom === 'md' ? 'mb-8' : '',
          ]"
          v-for="(item, idx) in list"
          :key="item.$key"
        >
          <v-card>
            <v-card-title>
              <div class="d-flex" style="width: 100%">
                <order-arrows @prev="prev(idx)" @next="next(idx)" />
                <div class="ml-4">
                  {{ item.title.ru }}
                </div>
                <div style="margin: 0 0 0 auto">
                  <v-btn icon fab small @click="edit(idx)"><v-icon small>fa-pencil</v-icon></v-btn>
                  <v-btn icon fab small @click="remove(idx)"><v-icon small>fa-trash</v-icon></v-btn>
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <error-handler>
                <block-details-provider
                  :fields="fields"
                  v-model="source"
                  @input="onInput"
                  :block="item"
                  v-slot="{ view, props, listeners }"
                >
                  <component
                    :is="view"
                    v-bind="props"
                    v-on="listeners"
                  />
                </block-details-provider>
                <template #error="{ error }">
                  <blocks-editor-field-fallback v-bind="{ error }" />
                </template>
              </error-handler>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </template>

    <template #dialog="{ model, input }">
      <div>
        <div class="text-h6 mb-8">Редактирование блока</div>
        <block-editor :options="options" :value="model" @input="input" />
      </div>
    </template>
  </list-editor-light>
</template>

<script>
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'
import OrderArrows from '@/components/OrderArrows.vue'
import BlockEditor from './BlockEditor.vue'
import ErrorHandler from '@/components/Error/ErrorHandler.vue'
import BlockDetailsProvider from './BlockDetailsProvider.vue'
import BlocksEditorFieldFallback from './BlocksEditorField/BlocksEditorFieldFallback.vue'
import isEqual from 'lodash/isEqual'

export default {
  components: {
    ErrorHandler,
    OrderArrows,
    BlockEditor,
    ListEditorLight,
    BlockDetailsProvider,
    BlocksEditorFieldFallback
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      source: { ...this.value }
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.source)) return
        this.source = { ...value }
      }
    }
  },
  computed: {
    options () {
      return this.fields.map(({ name, label }) => ({ name, label }))
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.source)
    }
  }
}
</script>
