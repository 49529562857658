<template>
  <div class="clinic-articles">
    <ItemsOrderProvider
      v-model="items"
      v-slot="{ prev, next }"
    >
      <ul class="clinic-articles__list" v-if="items.length">
        <li
          class="clinic-articles__list-item"
          style="display: flex; align-items: center"
          v-for="(item, idx) in items"
          :key="item.key"
        >
          <order-arrows
            :last="idx === items.length - 1"
            :first="idx === 0"
            @prev="prev(idx)"
            @next="next(idx)"
          />
          <div v-if="item.post">
            {{ item.post.title }}
          </div>
          <BlogArticleSelect
            v-else
            v-model="item.post"
            outlined
            hide-details
            dense
          />
          <v-btn
            @click="remove(idx)"
            color="error"
            icon
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </li>
      </ul>
    </ItemsOrderProvider>


    <div>
      <v-btn @click="add" large color="success">
        <v-icon small left>mdi-plus</v-icon>
        Добавить статью
      </v-btn>
    </div>
  </div>
</template>

<script>
import ItemsOrderProvider from '@/components/ItemsOrderProvider.vue'
import BlogArticleSelect from '@/components/Blog/BlogArticleSelect.vue'
import OrderArrows from '@/components/OrderArrows.vue'
import sortBy from 'lodash/sortBy'

export default {
  components: {
    OrderArrows,
    ItemsOrderProvider,
    BlogArticleSelect
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    const items = sortBy(this.value, 'order')
    return {
      items: items.map(({ post }) => this.create(post))
    }
  },
  watch: {
    items: {
      deep: true,
      handler (items) {
        this.$emit('input', items
          .map(({ post }, order) => ({ post, order }))
          .filter(({ post }) => post)
        )
      }
    }
  },
  methods: {
    create (post = null) {
      return {
        key: Math.random(),
        post
      }
    },
    add () {
      this.items.push(this.create())
    },
    remove (idx) {
      if (!confirm('Удалить?')) return
      this.$delete(this.items, idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-articles__list {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  & > * {
    padding: 0;
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.clinic-articles__list-item {
  display: flex;
  grid-gap: 12px;
}
</style>
