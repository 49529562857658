<template>
  <list-editor-light
    v-bind="{
      ...$attrs,
      items: $attrs.value,
      dialogOptions: { maxWidth: 500 }
    }"
    v-on="$listeners"
  >
    <template #content="{ item, edit, idx}">
      <a href="#" @click.prevent="() => edit(idx)">
        {{ item.name }}
      </a>
    </template>
    <template #dialog="{ model, input }">
      <div>
        <service-select autofocus :value="model" @input="input" />
      </div>
    </template>
  </list-editor-light>
</template>

<script>
import ServiceSelect from '@/components/Service/ServiceSelect.vue'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'

export default {
  components: {
    ListEditorLight,
    ServiceSelect,
  },
}
</script>
