<template>
  <AutocompleteAsync
    v-bind="attrs"
    v-on="$listeners"
  />
</template>

<script>
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'
import posts from '../../services/posts'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        name: "post",
        placeholder: 'Выберите статью',
        itemText: 'title',
        itemValue: 'uuid',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    async load (params) {
      const { items } = await posts.getAll(params)
      return items
    },
  }
}
</script>
