<template>
  <div class="ch">
    <v-row>
      <v-col>
        Для десктопной версии
        <div class="ch__image-desktop ch__image ch__image--wide">
          <clinic-image-picker
            class=""
            v-model="form.desktop"
            @input="input"
          />
        </div>
        <image-requirements size="2256px" ratio="3:1" class="caption" />
      </v-col>
      <v-col cols="3">
        Для мобильной
        <div class="ch__image-mobile ch__image ch__image-square">
          <clinic-image-picker v-model="form.mobile" @input="input" />
        </div>
        <image-requirements size="800px" ratio="4:3" class="caption" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClinicImagePicker from './ClinicImagePicker.vue'
import isEqual from 'lodash/isEqual'

class Model {
  constructor (options) {
    const {
      mobile = null,
      desktop = null
    } = options || {}
    this.mobile = mobile
    this.desktop = desktop
  }
}

export default {
  components: {
    ClinicImagePicker
  },
  props: {
    value: {
      type: Object,
      default: () => new Model()
    }
  },
  data () {
    return {
      form: new Model(this.value)
    }
  },
  watch: {
    value (value) {
      if (isEqual(this.form, value)) return
      this.form = new Model(value)
    },
  },
  methods: {
    input () {
      this.$emit('input', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.ch__image {
  position: relative;
  background: #eee;
  &::before {
    content: '';
    display: block;
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
}

.ch__image--wide {
  &:before {
    padding-bottom: 26.5%;
  }
}

.ch__image-square {
  &:before {
    padding-bottom: 85%;
  }
}

.ch__image-mobile {
  // max-width: 300px;
}
</style>
