<template>
  <v-select
    v-on="$listeners"
    v-bind="{ ...$attrs, items }"
  />
</template>

<script>
import { BLOCK_MARGINS } from '@/services/clinics'

export default {
  data () {
    return {
      items: Object.values(BLOCK_MARGINS)
    }
  }
}
</script>
