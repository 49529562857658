<template>
  <div>
    <validation-provider slim name="photo" :rules="rules" v-slot="{ errors }">
      <image-select
        :ratio="ratio"
        name="photo"
        :value="value"
        @input="(e) => $emit('input', e)"
      />
      <div class="mt-2 mb-2 red--text" v-if="errors && errors.length">
        {{ errors[0] }}
      </div>
      <image-requirements size="2256px" class="mb-4" />
    </validation-provider>
  </div>
</template>

<script>
import ImageSelect from '@/components/elements/image-select.vue'

export default {
  components: {
    ImageSelect
  },
  props: {
    ratio: {
      type: [Number, Function]
    },
    rules: {
      type: [String, Object]
    },
    value: {
      type: Object
    }
  }
}
</script>
