<script>

import isUndefined from "lodash/isUndefined";

export default {
  model: {
    prop: 'source',
    event: 'input',
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    block: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      default: () => ([])
    }
  },
  render () {
    const field = this.allowedFields[this.block.name]
    if (!field) {
      throw Error(`View for block "${this.block.name}" not found`)
    }
    if (!field.view) {
      throw Error(`View "${field.name}" not found`)
    }
    return this.$scopedSlots.default(field)
  },
  computed: {
    allowedFields () {
      const fields = this.fields.map(({ value, name, view }) => {
        const isStringProp  = typeof value === 'string'
        let propsObj = {}
        if (isStringProp) {
          propsObj = { value: value }
        } else if (typeof value === 'object' && value !== null) {
          propsObj = value
        }

        const props = Object
          .entries(propsObj)
          .reduce((acc, [propName, fieldName]) => {
            acc[propName] = this.source[fieldName]
            return acc
          }, {})

        const listeners = {
          input: (payload) => {
            const data = isStringProp ? { [value]: payload } : payload
            this.$emit('input', {
              ...this.source,
              ...data
            })
          }
        }

        return {
          name,
          view,
          listeners,
          props: { ...props, block: this.block }
        }
      })

      return fields.reduce((acc, field) => ({
        ...acc,
        [field.name]: field
      }), {})
    }
  },
}
</script>
