<template>
  <div>
    <div v-if="label">
      {{ label }}
    </div>
    <div>
      <v-btn
        @click="locale = item"
        v-for="item in locales"
        :key="item"
        x-small
        depressed
        :color="item === locale ? 'primary' : ''"
      >
        {{ item }}
      </v-btn>
    </div>
    <quill
      :name="name"
      v-if="locale"
      :options="QUILL_EXTENDED"
      v-model="content"
    />
  </div>
</template>

<script>
import { QUILL_EXTENDED } from '@/components/elements/quilljs/configs'
import { locales } from '@/store/modules/app'

export default {
  props: {
    name: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      locale: 'ru',
      locales,
      QUILL_EXTENDED
    }
  },
  computed: {
    content: {
      get () {
        return this.locale ? this.value[this.locale] : null
      },
      set (content) {
        return this.$emit('input', {
          ...this.value,
          [this.locale]: content
        })
      }
    }
  }
}
</script>
