<template>
  <locale-field
    auto-grow
    rows="1"
    component="v-textarea"
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    dense
    hide-details
  />
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'

export default {
  components: {
    LocaleField
  }
}
</script>
