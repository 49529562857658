<template>
  <div v-html="content" class="ww-content" />
</template>

<script>
export default {
  props: {
    content: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.ww-content {
  img {
    display: block;
    max-width: 100%;
  }
}

.ww-content::v-deep {
  .ql-preface {
    background: #f7f7f7;
    padding: 1em;
    margin-bottom: 2em;
  }

  .image img {
    display: block;
    max-width: 100%;
  }
}
</style>
