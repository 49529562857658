<template>
  <iframe-container
    :ratio="ratio"
    :src="src"
    class="video-embed__frame-wrapper mt-4"
    />
</template>

<script>
import IframeContainer from '@/components/IframeContainer.vue'

export default {
  components: {
    IframeContainer
  },
  props: {
    ratio: {
      type: Number,
      required: true
    },
    videoId: {
      type: String,
      required: true
    }
  },
  computed: {
    src () {
      return `https://www.youtube.com/embed/${this.videoId}`
    }
  }
}
</script>
