<template>
  <list-editor-light
    v-bind="{
      ...$attrs,
      items: $attrs.value,
      dialogOptions: { maxWidth: 500 },
    }"
    v-on="$listeners"
  >
    <template #content="{ item, idx, edit }">
      <a href="#" @click.prevent="() => edit(idx)">
        {{ item.fullName }}
      </a>
    </template>
    <template #dialog="{ input, model }">
      <doctor-select autofocus :value="model" @input="input" />
    </template>
  </list-editor-light>
</template>

<script>
import DoctorSelect from '@/components/Doctor/DoctorSelect.vue'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'

export default {
  components: {
    DoctorSelect,
    ListEditorLight,
  },
}
</script>
