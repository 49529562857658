<template>
  <div class="v-gallery">
    <list-editor-light
      :dialog-options="{ maxWidth: 500 }"
      v-model="items"
      @input="$emit('input', $event)"
    >
      <template #content="{ item }">
        <gallery-item-preview :item="item" />
      </template>
      <template #dialog="{ model, input }">
        <gallery-item :value="model" @input="input" />
      </template>
    </list-editor-light>
  </div>
</template>

<script>
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'
import GalleryItem from '@/components/Gallery/GalleryItem.vue'
import GalleryItemPreview from '@/components/Gallery/GalleryItemPreview.vue'

export default {
  components: {
    GalleryItemPreview,
    GalleryItem,
    ListEditorLight,
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      items: this.value
    }
  },
}
</script>

<style lang="scss" scoped>
.cg__item {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.cg__item-photo {
  width: 120px;
  height: 80px;
  display: block;
  object-fit: cover;
}

.cg__item-title {

}
</style>
