var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-editor-light',{attrs:{"dialog-options":{ maxWidth: 800 }},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var list = ref.list;
var create = ref.create;
var remove = ref.remove;
var prev = ref.prev;
var edit = ref.edit;
var next = ref.next;
return [_c('v-btn',{attrs:{"block":"","x-large":"","color":"primary"},on:{"click":create}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-plus")]),_vm._v(" Добавить ")],1),_c('v-row',_vm._l((list),function(item,idx){return _c('v-col',{key:item.$key,class:[
          item.top === 'sm' ? 'mt-0' : item.top === 'md' ? 'mt-8' : '',
          item.bottom === 'sm' ? 'mb-0' : item.bottom === 'md' ? 'mb-8' : '' ],attrs:{"cols":12}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('order-arrows',{on:{"prev":function($event){return prev(idx)},"next":function($event){return next(idx)}}}),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(item.title.ru)+" ")]),_c('div',{staticStyle:{"margin":"0 0 0 auto"}},[_c('v-btn',{attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return edit(idx)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-pencil")])],1),_c('v-btn',{attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return remove(idx)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)],1)],1)]),_c('v-card-text',[_c('error-handler',{scopedSlots:_vm._u([{key:"error",fn:function(ref){
        var error = ref.error;
return [_c('blocks-editor-field-fallback',_vm._b({},'blocks-editor-field-fallback',{ error: error },false))]}}],null,true)},[_c('block-details-provider',{attrs:{"fields":_vm.fields,"block":item},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var view = ref.view;
        var props = ref.props;
        var listeners = ref.listeners;
return [_c(view,_vm._g(_vm._b({tag:"component"},'component',props,false),listeners))]}}],null,true),model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}})],1)],1)],1)],1)}),1)]}},{key:"dialog",fn:function(ref){
        var model = ref.model;
        var input = ref.input;
return [_c('div',[_c('div',{staticClass:"text-h6 mb-8"},[_vm._v("Редактирование блока")]),_c('block-editor',{attrs:{"options":_vm.options,"value":model},on:{"input":input}})],1)]}}]),model:{value:(_vm.source.blocks),callback:function ($$v) {_vm.$set(_vm.source, "blocks", $$v)},expression:"source.blocks"}})}
var staticRenderFns = []

export { render, staticRenderFns }