var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-editor-light',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(ref){
var item = ref.item;
var idx = ref.idx;
var edit = ref.edit;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return edit(idx); })($event)}}},[_vm._v(" "+_vm._s(item.fullName)+" ")])]}},{key:"dialog",fn:function(ref){
var input = ref.input;
var model = ref.model;
return [_c('doctor-select',{attrs:{"autofocus":"","value":model},on:{"input":input}})]}}])},'list-editor-light',Object.assign({}, _vm.$attrs,
    {items: _vm.$attrs.value,
    dialogOptions: { maxWidth: 500 }}),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }