<template>
  <div>
    <div v-if="title" class="text-h6 mb-2">
      {{ title }}
    </div>

    <div style="max-width: 50%;">
      <v-img :aspect-ratio="2/1" v-if="item.photo" :src="item.photo.originalUrl" />
      <gallery-item-you-tube-video :ratio="0.5" v-if="item.embeddedVideo" :video-id="item.embeddedVideo"
      />
    </div>
  </div>
</template>

<script>
import { TYPE_PHOTO, TYPE_EMBEDDED_VIDEO } from '@/models/GalleryItem'
import GalleryItemYouTubeVideo from './GalleryItemYouTubeVideo.vue'

export default {
  components: {
    GalleryItemYouTubeVideo
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      TYPE_PHOTO,
      TYPE_EMBEDDED_VIDEO
    }
  },
  computed: {
    title () {
      const { ru, en } = this.item.title
      return ru || en
    }
  }
}
</script>
