<template>
  <v-autocomplete
    v-on="$listeners"
    v-bind="attrs"
    v-model="model"
    :search-input.sync="search"
  >
    <template
      v-for="(index, name) in $scopedSlots"
      v-slot:[name]="data"
    >
      <slot :name="name" v-bind="data"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { Notification } from 'element-ui'

export default {
  props: {
    load: {
      type: Function,
      required: true
    },
  },
  data () {
    return {
      model: null,
      loading: false,
      items: [],
      search: null,
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value && value.length) {
        this.loadItems()
      } else {
        this.items = []
      }
    }, 500)
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        prependInnerIcon: "mdi-magnify",
        clearable: true,
        returnObject: true,
        items: this.items,
        loading: this.loading,
        hideNoData: true,
      }
    }
  },
  methods: {
    async loadItems () {
      let error
      if (this.loading) return
      this.loading = true
      try {
        const items = await this.load({ search: this.search })
        this.items = items
      } catch (e) {
        error = e
      }
      this.loading = false
      if (error) {
        Notification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      }
    }
  }
}
</script>
