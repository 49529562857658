<template>
  <div class="iframe-container">
    <div
      class="iframe-container__ratio"
      :style="{ paddingBottom }"
    />
    <iframe
      class="iframe-container__iframe"
      v-bind="$attrs"
      :src="src"
      frameborder="0"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String
    },
    ratio: {
      type: [Number, Function],
      required: true
    }
  },
  computed: {
    paddingBottom () {
      let value
      if (typeof this.ratio === 'function') {
        value = this.ratio()
      } else if (typeof this.ratio === 'number') {
        value = this.ratio
      }
      return value !== undefined ? (value * 100) + '%' : null
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe-container {
  background: #eee;
  position: relative;
}
.iframe-container__ratio {
  display: block;
}
.iframe-container__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
