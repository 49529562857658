<template>
  <error-display :error="error" />
</template>

<script>
import ErrorDisplay from '@/components/Error/ErrorDisplay.vue'

export default {
  components: {
    ErrorDisplay
  },
  props: {
    error: {
      required: true
    }
  }
}
</script>
