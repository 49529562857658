<template>
  <div class="ca">
    <template v-if="editing">
      <quill
        :options="QUILL_BASIC"
        v-model="editableContent"
      />
      <div class="d-flex justify-space-between">
        <v-btn @click="save" color="success">Сохранить</v-btn>
        <v-btn @click="cancel">Отмена</v-btn>
      </div>
    </template>

    <v-row v-else>
      <v-col v-for="locale in locales" :key="locale">
        <div class="text-h5 mb-4">
          <span class="mr-8">{{ locale }}</span>
          <v-btn @click="edit(locale)" small>Изменить</v-btn>
        </div>
        <wysiwyg-content :content="content[locale]" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'
import { QUILL_BASIC } from '@/components/elements/quilljs/configs'
import { locales } from '@/store/modules/app'
import WysiwygContent from '@/components/WysiwygContent/WysiwygContent.vue'

export default {
  components: {
    LocaleField,
    WysiwygContent,
  },
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      locales,
      QUILL_BASIC,
      content: this.value,
      locale: null,
      editableContent: null,
      editing: false,
    }
  },
  watch: {
    content: {
      deep: true,
      handler (content) {
        this.$emit('input', content)
      }
    }
  },
  methods: {
    cancel () {
      this.editing = false
      this.locale = null
      this.editableContent = null
    },
    edit (locale) {
      this.editing = true
      this.locale = locale
      this.editableContent = this.content[locale]
    },
    save () {
      this.content[this.locale] = this.editableContent
      this.cancel()
    }
  }
}
</script>
