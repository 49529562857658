<script>
export default {
  data () {
    return {
      error: null
    }
  },
  errorCaptured (e) {
    this.error = e
    console.error(this.error)
    return false
  },
  render () {
    if (this.error) {
      return this.$scopedSlots.error ? this.$scopedSlots.error({ error: this.error }) : null
    } else {
      return this.$scopedSlots.default()
    }

  }
}
</script>
